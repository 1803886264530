 .container {
  background: #eee;
}

 .item {
  padding: 50px 0;
}

 .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

 .text {
  color: #666;
  margin-bottom: 10px;
}

 .linkText {
  color: #111;
  font-weight: bold;
  position: relative;
  top: 1px;
  margin-right: 5px;
}

 .link {
  color: #e72430;
}
